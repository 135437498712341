module.exports = {
  currencyCookieName: 'vsf-currency',
  countryCookieName: 'vsf-country',
  localeCookieName: 'vsf-locale',
  cartCookieName: 'vsf-cart',
  customerCookieName: 'vsf-customer',
  storeCookieName: 'vsf-store',
  messageCookieName: 'vsf-message',
  marketCookieName: 'vsf-market',
  wishlistCookieName: 'vsf-wishlist',
  segmentsCookieName: 'vsf-segments',
  languageCookieName: 'vsf-accept-language',
  geolocatorCookieName: 'vsf-geolocator-data',
};
